import { MutableRefObject, useEffect } from 'react';

export const useClickOutside = <T extends HTMLElement>(
  ref: MutableRefObject<T>,
  callback: (evt: MouseEvent) => void,
) => {
  useEffect(() => {
    const eventHandler = (evt: MouseEvent) => {
      if (!ref.current || ref.current.contains(evt.target as Node)) {
        return;
      }
      callback(evt);
    };
    document.addEventListener('mousedown', eventHandler);

    return () => {
      document.removeEventListener('mousedown', eventHandler);
    };
  }, [ref, callback]);
};
