/* eslint-disable */
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import axios from 'axios';
import { msalInstance } from '..';
import { loginRequest } from '../auth/config';

const axiosInstance = axios.create();

// @ts-ignore
axiosInstance.interceptors.request.use(async config => {
  try {
    const account = msalInstance.getAllAccounts()[0];
    const tokenResponse = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    });

    config.headers!.authorization = `Bearer ${tokenResponse.accessToken}`;
    return config;
  } catch (error) {
    console.debug('Authorisation error: ', error);
    if (error instanceof InteractionRequiredAuthError) {
      return msalInstance.acquireTokenRedirect(loginRequest);
    }
    return msalInstance.loginRedirect(loginRequest);
  }
});

export default axiosInstance;
