import { AxiosResponse } from 'axios';
import { Login } from '../models';
import axiosInstance from '.';
import { getEnvironmentVariable } from '../utils/env.helpers';

const API_ROOT = getEnvironmentVariable('REACT_APP_CORE_API');

export const postAuthenticate = (
  data: string,
): Promise<AxiosResponse<Login>> => {
  const headers = { 'Content-Type': 'text/plain' };
  return axiosInstance.post(`${API_ROOT}/bin/ci/authenticate`, data, {
    headers,
  });
};
