/* eslint-disable @typescript-eslint/no-explicit-any */

export const PAGES: any = {
  HOME: 'Home',
  ONCOLOGY: 'Oncology',
  BIOPHARMA: 'BioPharmaceuticals',
  ALEXION: 'Alexion - Rare Disease',
  DATA_SCIENCE: 'Data Science',
};

export const PAGES1: any = {
  HOME: 'Home',
};

export const homeText =
  'Connected Insights is AstraZeneca’s App Store for data and AI tools related to competitive intelligence. It is being pioneered by BioPharma, Oncology and Alexion competitive intelligence teams to create a one-stop shop for commonly requested insight. Underpinning the applications are several data products and our Knowledge Graph, CIKG.';

export const oncologyText =
  'We have a bold ambition to provide cures for cancer in every form. We are following the science to understand cancer and all its complexities to discover, develop and deliver life-changing treatments and increase the potential to save the lives of people around the world.';

export const biopharmaText =
  'Through our work in BioPharmaceuticals, we want to transform healthcare, change the lives of billions of people for the better and address some of the biggest healthcare challenges facing humankind. There remains a huge unmet need in treating people with chronic diseases. Our ambition is to stop the progress of these often degenerative, debilitating and life-threatening conditions. So many more people can live better, healthier lives.';

export const alexionText =
  'Our mission is to transform the lives of people affected by rare diseases By understanding patients’ unique needs, we can research and develop innovative medicines, support access and advocate for the rare disease community.';

export const dataScienceText =
  'We are using data science and artificial intelligence (AI) to increase productivity within research and development to find new ways to treat, prevent or even cure diseases. We can now capture more data than ever before. Data science and AI can help scientists turn data into knowledge, allowing them to accelerate drug discovery and bring life-changing medicines to patients.';
