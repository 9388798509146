/* eslint-disable @typescript-eslint/no-explicit-any */
import { useIsOnTablet } from '@azu-ignite/ci-core-ui-pattern-lib';
import { useNavigate } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserCookieConsent } from '../../components';
import {
  PageContainer,
  Layout,
  Tabs,
  Tab,
  TextContainer,
  Heading,
  Description,
  PeopleContainer,
} from './MeetTeam.styles';
import Person from './Person';
import { teamData } from './TeamData';

export const MeetTeam = () => {
  const navigate = useNavigate();
  const isTabletView = useIsOnTablet();

  if (!teamData?.length) {
    return null;
  }
  return (
    <>
      <UserCookieConsent />
      <Tabs isTabletView={isTabletView}>
        <Tab onClick={() => navigate('/')} isTabletView={isTabletView}>
          Home
        </Tab>
        <FontAwesomeIcon
          icon={faChevronRight}
          size="xs"
          style={{
            color: '#950050',
            paddingRight: '10px',
          }}
        />
        <Tab isTabletView={isTabletView}>Meet The Team</Tab>
      </Tabs>
      <PageContainer>
        <Layout isTabletView={isTabletView}>
          <TextContainer>
            <Heading isTabletView={isTabletView}>Meet The Team</Heading>
            <Description isTabletView={isTabletView}>
              Connected Insights is an IT service, within IGNITE’s Enterprise AI
              Services, supporting competitive and strategic intelligence teams
              in BioPharmaceuticals, Oncology and Alexion. We provide an
              internal tailored data, software and AI capability built to AZ’s
              in-house competitive intelligence and strategy teams and partner
              with other IT services for effective enterprise delivery. Our data
              assets and applications are used to create, manage and disseminate
              insights regarding our competitors.
              <br />
              <br />
              Below are members of the Connected Insights team, please contact
              Jasen Chooramun for further info.
            </Description>
          </TextContainer>
          <PeopleContainer isTabletView={isTabletView}>
            {teamData.map((person: any) => (
              <Person
                key={person?.name}
                name={person?.name}
                role={person?.role}
                email={person?.email}
                photo={person?.photo}
              />
            ))}
          </PeopleContainer>
        </Layout>
      </PageContainer>
    </>
  );
};
