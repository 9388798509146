/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppMetaData, AppMetaDataTile } from '../../models';

/** Filter and convert App Meta data to display tiles */
export const addAppTileParser = (item: AppMetaData) => {
  return {
    title: item.sectionName,
    id: item.id,
    items: item?.appList?.reduce((result: AppMetaDataTile[], app) => {
      if (app.appActive) {
        const output = {
          appName: app.appName,
          appSummary: app.shortDescription,
          appUrl: app.appUrl,
          appIcon: app.appIconUrl,
          isAi: app.ai,
          isComingSoon: app.comingSoon,
          isBeta: app.beta,
          isRestricted: app.locked,
          order: app.order,
        };
        return result
          .concat(output)
          .sort((a: any, b: any) => a.order - b.order);
      }
      return result;
    }, []),
  };
};

export const appMetaDataParser = (appMetaData: AppMetaData[]) => {
  return appMetaData.map(item => addAppTileParser(item));
};
