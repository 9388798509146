/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { PropsWithChildren } from 'react';
import { Client } from '@microsoft/microsoft-graph-client';
import {
  Footer,
  Header,
  useGetProfileOptions,
} from '@azu-ignite/ci-design-system';
import { loginRequest } from '../auth/config';
import {
  Home,
  HomeEdit,
  NotFound,
  ComingSoon,
  MeetTeam,
  ConnectedInsights,
} from '../pages';

export const SecureRoute = ({ children }: PropsWithChildren<unknown>) => {
  const { instance, accounts } = useMsal();

  const getProfilePhoto = async () => {
    try {
      const account = accounts?.[0];
      const accessToken = await instance.acquireTokenSilent({
        scopes: ['User.Read'],
        account,
      });
      const client = Client.init({
        authProvider: done => done(null, accessToken.accessToken),
      });
      return await client.api('/me/photo/$value').get();
    } catch (error) {
      console.error(error);
    }
    return undefined;
  };

  const getProfileData = () => {
    const account = accounts?.[0];
    if (!account) return { firstName: '', lastName: '', email: '' };

    const [lastName = '', firstNameWithParentheses = ''] =
      account.name?.split(',') ?? [];
    const [firstName = ''] = firstNameWithParentheses.split('(');

    return {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: account.username ?? '',
    };
  };

  const profileOptions = useGetProfileOptions(
    getProfilePhoto,
    getProfileData(),
    () => instance.logoutRedirect(),
  );

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      <div>
        <Header profileOptions={profileOptions} platformUrl="/" />
        <div>{children}</div>
        <Footer />
      </div>
      {/* <LayoutBeta headerProps={headerProps}>{children}</LayoutBeta> */}
    </MsalAuthenticationTemplate>
  );
};

export const AppRoutes = [
  {
    path: '/',
    index: true,
    element: (
      <SecureRoute>
        <Home />
      </SecureRoute>
    ),
  },
  {
    path: '/config-edit-home-ci-page',
    index: true,
    element: (
      <SecureRoute>
        <HomeEdit />
      </SecureRoute>
    ),
  },
  {
    path: '/meet-team',
    element: (
      <SecureRoute>
        <MeetTeam />
      </SecureRoute>
    ),
  },
  {
    path: '/connected-insights',
    element: (
      <SecureRoute>
        <ConnectedInsights />
      </SecureRoute>
    ),
  },
  {
    path: '/coming-soon',
    element: (
      <SecureRoute>
        <ComingSoon />
      </SecureRoute>
    ),
  },
  {
    path: '*',
    element: (
      <SecureRoute>
        <NotFound />
      </SecureRoute>
    ),
  },
];
