import { getEnvironmentVariable } from '../utils/env.helpers';

export const msalConfig = {
  auth: {
    clientId: getEnvironmentVariable('REACT_APP_AUTH_CLIENTID'), // Newsflow client id
    authority: `https://login.microsoftonline.com/af8e89a3-d9ac-422f-ad06-cc4eb4214314`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    // redirectUri: getEnvironmentVariable('REACT_APP_AUTH_REDIRECT_URL'),
    postLogoutRedirectUri: `${getEnvironmentVariable(
      'REACT_APP_AUTH_REDIRECT_URL',
    )}`,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [`${getEnvironmentVariable('REACT_APP_AUTH_CLIENTID')}/.default`],
};
