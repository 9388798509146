import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { HashRouter as Router } from 'react-router-dom';
import { defaultTheme } from '@azu-ignite/ci-core-ui-pattern-lib';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { PersistGate } from 'redux-persist/lib/integration/react';

import App from './App';
import { persistor, store } from './state/store';
import { msalConfig } from './auth/config';

import './index.css';

export const msalInstance = new PublicClientApplication(msalConfig);
const roles = msalInstance?.getAllAccounts()[0]?.idTokenClaims?.roles || [];

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MsalProvider instance={msalInstance}>
          <ThemeProvider theme={defaultTheme}>
            <Router>
              <App roles={roles} />
            </Router>
          </ThemeProvider>
        </MsalProvider>
      </PersistGate>
    </Provider>
  </StrictMode>,
);
