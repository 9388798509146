import { encode } from 'js-base64';
import { LoginFormData } from '../../pages/Login/Login.types';

export const getConfigObj = (data: LoginFormData) => {
  const baseStr = encode(
    JSON.stringify({
      username: data.PRID,
      password: data.password,
    }),
  );
  return baseStr;
};
