/* eslint-disable @typescript-eslint/no-explicit-any */
import { useIsOnTablet } from '@azu-ignite/ci-core-ui-pattern-lib';
import { useNavigate } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserCookieConsent } from '../../components';
import {
  PageContainer,
  Layout,
  Tabs,
  Tab,
  TextContainer,
  Heading,
  Description,
} from './ConnectedInsights.styles';

export const ConnectedInsights = () => {
  const navigate = useNavigate();
  const isTabletView = useIsOnTablet();

  return (
    <>
      <UserCookieConsent />
      <Tabs isTabletView={isTabletView}>
        <Tab onClick={() => navigate('/')} isTabletView={isTabletView}>
          Home
        </Tab>
        <FontAwesomeIcon
          icon={faChevronRight}
          size="xs"
          style={{
            color: '#950050',
            paddingRight: '10px',
          }}
        />
        <Tab isTabletView={isTabletView}>Connected Insights</Tab>
      </Tabs>
      <PageContainer>
        <Layout isTabletView={isTabletView}>
          <TextContainer>
            <Heading isTabletView={isTabletView}>
              What is Connected Insights?
            </Heading>
            <Description isTabletView={isTabletView}>
              Connected Insights is an IT service, within IGNITE’s Enterprise AI
              Services, supporting competitive and strategic intelligence teams
              in BioPharmaceuticals, Oncology and Alexion. We provide an
              internal tailored data, software and AI capability built to
              empower AZ’s in-house competitive intelligence and strategy teams
              and partner with other IT services for effective enterprise
              delivery.
              <br />
              <br />
              It transforms how competitive intelligence is created,
              disseminated, and consumed across AstraZeneca into a rich,
              digital, interconnected, on-demand experience. To provide a more
              comprehensive global view, we also design digital solutions to
              combine competitive intelligence with internal insights to make
              better-informed decisions and develop more effective strategies.
              <br />
              <br />
              Using data pipelines, our Competitive Intelligence Knowledge Graph
              (CIKG), interactive visualisations and machine learning, we seek
              to integrate key external and internal data sources, generate
              complex interactive information, and accelerate our teams of
              competitive intelligence and strategy professionals to provide a
              clear advantage for AstraZeneca. We value creating an excellent
              customer experience, reliable data, and effective engineering
              practices.
            </Description>
          </TextContainer>
        </Layout>
      </PageContainer>
    </>
  );
};
