import { device } from '@azu-ignite/ci-core-ui-pattern-lib';
import styled from 'styled-components';

export const AppSectionContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 140px;
  grid-gap: ${props => props.theme.spaces.lg};
  margin: ${props => props.theme.spaces.lg} 0;
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: ${props => props.theme.spaces.xl};
  }
  @media ${device.desktop} {
    grid-template-rows: 160px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: ${props => props.theme.spaces.xl};
  }

  @media ${device.largeDesktop} {
    grid-template-rows: 160px;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: ${props => props.theme.spaces.xl};
  }
`;
