/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useReducer, useEffect } from 'react';
import { Input } from '@azu-ignite/ci-core-ui-pattern-lib';
import { useAppDispatch } from '../../state/hooks';
import { createTileApp } from '../../state/slices';
import { generateId } from '../../utils/helpers';
import {
  SectionContainer,
  CancelButton,
  UpdateButton,
} from './CreateAppTile.styles';

type State = {
  sectionName: string;
};

type Action = {
  type: 'UPDATE_FIELD';
  name: string;
  value: any;
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return { ...state, [action.name]: action.value };
    default:
      return state;
  }
};

export const CreateSection = ({
  selectedSection = {},
  apiResponse,
  closeModal,
}: {
  selectedSection: any;
  apiResponse: any;
  closeModal: () => void;
}) => {
  const dispatchStore = useAppDispatch();
  const [state, dispatch] = useReducer(reducer, {
    sectionName: '',
  });

  useEffect(() => {
    if (selectedSection?.sectionName) {
      dispatch({
        type: 'UPDATE_FIELD',
        name: 'sectionName',
        value: selectedSection?.sectionName,
      });
    }
  }, []);

  const updateChanges = () => {
    // Create new section
    if (!selectedSection?.sectionName) {
      const newSection = {
        sectionName: state.sectionName,
        order: apiResponse.length + 1,
        id: generateId(24),
        appList: [],
      };
      // @ts-ignore
      dispatchStore(createTileApp(newSection));
    }

    // Edit existing section
    if (selectedSection?.sectionName) {
      const editedSection = {
        ...selectedSection,
        sectionName: state.sectionName,
      };
      // @ts-ignore
      dispatchStore(createTileApp(editedSection));
    }

    closeModal();
  };

  const handleInputChange = (name: keyof State, value: string) => {
    dispatch({ type: 'UPDATE_FIELD', name, value });
  };

  return (
    <div>
      <SectionContainer>
        <Input
          id="sectionName"
          name="sectionName"
          value={state.sectionName}
          onChange={e => handleInputChange('sectionName', e.target.value)}
          type="text"
          label="SECTION NAME"
          placeholder=""
        />
      </SectionContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginBottom: '30px',
        }}
      >
        <CancelButton
          onClick={() => {
            closeModal();
          }}
          id="cancel-btn"
        >
          Cancel
        </CancelButton>
        <UpdateButton onClick={() => updateChanges()} id="update-btn">
          Save
        </UpdateButton>
      </div>
    </div>
  );
};
