/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';

import axiosInstance from '.';
import { AppMetaData } from '../models';
import { getEnvironmentVariable } from '../utils/env.helpers';

const API_ROOT = getEnvironmentVariable('REACT_APP_CORE_API');

export const getAppMetaData = async (): Promise<AxiosResponse<AppMetaData[]>> =>
  axiosInstance.get(`${API_ROOT}/api/home/apps`);

export const addAppTile = async (
  requestBody: AppMetaData,
): Promise<void | AxiosResponse<AppMetaData>> =>
  axiosInstance.post(`${API_ROOT}/api/home`, requestBody);

export const deleteSection = (
  sectionId: string,
): Promise<AxiosResponse<AppMetaData>> =>
  axiosInstance.delete(`${API_ROOT}/api/home/${sectionId}`);
