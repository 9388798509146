import CookieConsent from 'react-cookie-consent';
import { useTheme } from 'styled-components';

export const UserCookieConsent = () => {
  const theme = useTheme();
  return (
    <CookieConsent
      cookieName="cookie-consent"
      expires={999}
      overlay
      location="top"
      style={{
        fontFamily: theme.fonts.family,
        padding: theme.spaces.md,
      }}
      buttonStyle={{
        border: `1px solid ${theme.items.primary}`,
        background: theme.items.primary,
        color: theme.colours.white,
        outline: 'none',
        fontFamily: 'inherit',
        fontSize: '14px',
        lineHeight: '15px',
        transition: '0.24s',
        borderRadius: '5px',
        padding: '6px 12px',
        height: '36px',
      }}
    >
      This website uses cookies to enhance the user experience,
    </CookieConsent>
  );
};
