import { H1, Link, P, Warning } from '@azu-ignite/ci-core-ui-pattern-lib';

import { externalEmails } from '../../App.constants';
import { WarningBrandLogoContainer, WarningImage } from '../../App.styles';
import errorImage from '../../images/ci-error.png';
import logoPrimary from '../../images/ci-logo-primary.png';

export const NotFound = () => (
  <Warning image={<WarningImage src={errorImage} alt="Unexpected Error" />}>
    <WarningBrandLogoContainer>
      <img src={logoPrimary} alt="Connected Insights" />
    </WarningBrandLogoContainer>
    <H1>Something went wrong!</H1>
    <P>Sorry the page you are looking for cannot be accessed.</P>
    <P>
      If you require additional information, please contact{' '}
      <Link href={`mailto:${externalEmails.support}`} id="support-link">
        {externalEmails.support}
      </Link>
    </P>
  </Warning>
);
