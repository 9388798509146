import styled from 'styled-components';

export const PageContainer = styled.div`
  background: linear-gradient(to bottom, #fbe3ed, #f7e8f2, #f3edf0);
`;

export const Layout = styled.div<{ isTabletView?: boolean }>`
  padding: 30px 120px 40px 60px;

  ${props =>
    props.isTabletView &&
    `
      padding: 0px 30px 20px;
  `}
`;

export const TextContainer = styled.div`
  color: #1e1f20;
  margin-bottom: 60px;
`;

export const Heading = styled.p<{ isTabletView?: boolean }>`
  line-height: 22px;
  font-size: 23px;
  font-family: 'Lexia';

  ${props =>
    props.isTabletView &&
    `
      font-size: 22px;
  `}
`;

export const Description = styled.p<{ isTabletView?: boolean }>`
  line-height: 26px;
  font-size: 16px;
  padding-top: 30px;

  ${props =>
    props.isTabletView &&
    `
      padding-top: 20px;
      font-size: 15px;
  `}
`;

export const Tabs = styled.div<{ isTabletView?: boolean }>`
  background: #fbe3ed;
  height: 60px;
  // position: sticky;
  top: 61px;
  color: #fff;
  zindex: 999;
  font-family: 'HelveticaNeue';
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 60px;

  ${props =>
    props.isTabletView &&
    ` 
      padding-left: 30px;
      font-size: 13px;
      height: 54px;
  `}
`;

export const Tab = styled.div<{ selected?: boolean; isTabletView?: boolean }>`
  padding-right: 10px;
  color: #950050;
  font-weight: ${props => (props.selected ? '700' : '300')};
  position: relative;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    color: #610036;
  }
`;
