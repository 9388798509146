import { device } from '@azu-ignite/ci-core-ui-pattern-lib';
import styled from 'styled-components';
import backgroundImage from '../../images/ci-background.jpg';

export const InfoHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
`;

export const InfoContainer = styled.div`
  display: block;
  background-color: white;
  margin: 20px;
  border-radius: 5px;
  @media ${device.mobile} {
    display: flex;
  }
`;

export const InfoDetailsContainer = styled.div`
  display: block;
  margin: ${props => props.theme.spaces.lg};
  width: 75%;
`;

export const InfoLogo = styled.img`
  height: auto;
  width: 150px;
  padding: ${props => props.theme.spaces.md} 0;
`;

export const InfoImage = styled.div`
  display: none;
  background: url(${backgroundImage}) no-repeat center center;
  background-size: cover;
  overflow: hidden;
  border-radius: 5px;
  width: 20%;
  height: auto;
  justify-content: center;
  align-items: center;
  @media ${device.mobile} {
    display: inline;
  }
`;

export const InfoStraplineText = styled.div`
  padding: ${props => props.theme.spaces.lg} 0;
  color: #566372;
  font-size: 16px;
  line-height: 1.25;
`;

export const InfoDisclaimerText = styled.div`
  padding: ${props => props.theme.spaces.md} 0;
  font-size: 12px;
  color: #5e6a79;
  line-height: 1.333;
  @media ${device.mobile} {
    padding-top: ${props => props.theme.spaces.xl};
    padding-bottom: none;
  }
`;
