import { H1, Link, P, Warning } from '@azu-ignite/ci-core-ui-pattern-lib';

import { externalEmails } from '../../App.constants';
import { WarningBrandLogoContainer, WarningImage } from '../../App.styles';
import comingSoonImage from '../../images/ci-coming-soon.png';
import logoPrimary from '../../images/ci-logo-primary.png';

export const ComingSoon = () => (
  <Warning image={<WarningImage src={comingSoonImage} alt="Coming soon" />}>
    <WarningBrandLogoContainer>
      <img src={logoPrimary} alt="Connected Insights" />
    </WarningBrandLogoContainer>
    <H1>We are building something awesome!</H1>
    <P>
      We are currently building new app features while finishing up some ongoing
      projects.
    </P>
    <P>
      For further information or updates, please contact{' '}
      <Link href={`mailto:${externalEmails.support}`} id="support-link">
        {externalEmails.support}
      </Link>
    </P>
  </Warning>
);
