import { Loader } from '@azu-ignite/ci-design-system';
import { useEffect } from 'react';

import { AppSections, UserCookieConsent } from '../../components';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { appMetaDataSelector } from '../../state/selectors';
import { fetchAppMetaData } from '../../state/slices';
import { InfoLinkText, Divider } from './HomeEdit.styles';

export const HomeEdit = () => {
  const dispatch = useAppDispatch();

  const { status: appStatus, sections } = useAppSelector(appMetaDataSelector);

  const handleClick = (url: string) => {
    window.location.href = url;
  };

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchAppMetaData());
  }, []);

  const isLoading = appStatus === 'loading';
  const isFailed = appStatus === 'failed';
  if (isFailed) {
    return null;
  }
  return (
    <>
      <UserCookieConsent />
      <InfoLinkText id="ci-info-page" to="/info">
        What is Connected Insights?
      </InfoLinkText>
      <Divider />
      {isLoading && <Loader isActive={isLoading} />}
      {appStatus === 'succeeded' && (
        <AppSections sections={sections} handleClick={handleClick} />
      )}
    </>
  );
};
