/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppMetaDataSectionView, AppMetaData } from '../../models';
import {
  getAppMetaData,
  addAppTile,
  deleteSection,
} from '../../services/metaDataService';
import { appMetaDataParser } from '../parsers/metaDataParser';
import { loadingState, resetStatus } from '../state.helpers';
import { StateStatus } from '../state.types';

/* Define state structure */
type AppMetaDataState = {
  apiResponse: AppMetaData[];
  sections: AppMetaDataSectionView[];
  hasRole: boolean;
  isEditMode: boolean;
} & StateStatus;

/* Set initial state */
const initialState: AppMetaDataState = {
  apiResponse: [],
  sections: [],
  hasRole: false,
  isEditMode: false,
  status: 'idle',
  error: null,
};

export const fetchAppMetaData = createAsyncThunk(
  'metaData/fetchAppMetaData',
  async () => {
    const response = await getAppMetaData();
    return {
      data: response.data,
    };
  },
);

export const createTileApp = createAsyncThunk(
  'metaData/createTileApp',
  async (bodyRequest: AppMetaData, { dispatch }) => {
    const response = await addAppTile(bodyRequest);

    if (response) {
      dispatch(fetchAppMetaData());
    }

    return {
      data: response?.data,
    };
  },
);

export const removeSection = createAsyncThunk(
  'metaData/removeSection',
  async (sectionId: string, { dispatch }) => {
    const response = await deleteSection(sectionId);

    if (response) {
      dispatch(fetchAppMetaData());
    }

    return {
      data: response?.data,
    };
  },
);

const appMetaDataSlice = createSlice({
  name: 'appMetaData',
  initialState,
  reducers: {
    resetAppMetaData: () => initialState,
    resetAppMetaDataStatus: resetStatus,
    hasUserEditorRole: (state, action) => {
      state.hasRole = action.payload;
    },
    switchEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAppMetaData.pending, loadingState)
      .addCase(fetchAppMetaData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { payload } = action;
        state.apiResponse = payload.data;
        state.sections = appMetaDataParser(payload.data);
      })
      .addCase(fetchAppMetaData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const {
  resetAppMetaData,
  resetAppMetaDataStatus,
  hasUserEditorRole,
  switchEditMode,
} = appMetaDataSlice.actions;
export default appMetaDataSlice.reducer;
