/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { H2, Tile, Alert, Button } from '@azu-ignite/ci-core-ui-pattern-lib';
import { Fragment, useState, useMemo } from 'react';
import {
  faPlusCircle,
  faTrash,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { appMetaDataSelector } from '../../state/selectors';
import {
  createTileApp,
  removeSection,
  switchEditMode,
} from '../../state/slices';
import { AppSectionContainer } from './AppSections.styles';
import { AppSectionsProps } from './AppSections.types';
import { Modal } from '../Modal/Modal';
import { CreateAppTile } from './CreateAppTile';
import { CreateSection } from './CreateSection';
import { Switch } from './Switch';

export const AppSections = ({ sections, handleClick }: AppSectionsProps) => {
  const dispatchStore = useAppDispatch();
  const { apiResponse, hasRole, isEditMode } =
    useAppSelector(appMetaDataSelector);

  const [show, setShow] = useState<boolean>(false);
  const [showSection, setShowSection] = useState<boolean>(false);

  const [selectedSectionId, setSelectedSectionId] = useState<string>('');
  const [selectedApp, setSelectedApp] = useState<any>(null);

  const findSection = useMemo(() => {
    if (selectedSectionId) {
      return apiResponse.find(section => section?.id === selectedSectionId);
    }
    return {};
  }, [selectedSectionId]);

  const handleToggle = (status: any) => {
    dispatchStore(switchEditMode(status));
  };

  // App-tile functions
  const onClickAddButton = (id: string) => {
    setShow(true);
    setSelectedSectionId(id);
    setSelectedApp(null);
  };

  const onClickRemoveButton = (e: any, id: string, item: any) => {
    e.stopPropagation();
    setSelectedApp(null);
    const section = apiResponse.find(el => el?.id === id);
    const finalSection = {
      ...section,
      appList: section?.appList?.filter(el => el?.order !== item?.order),
    };
    // @ts-ignore
    dispatchStore(createTileApp(finalSection));
  };

  const onClickEditButton = (e: any, id: string, item: any) => {
    e.stopPropagation();
    const section = apiResponse.find(el => el?.id === id);
    const app = section?.appList?.find(el => el?.appName === item?.appName);
    setSelectedSectionId(id);
    setSelectedApp(app);
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
    setSelectedSectionId('');
    setSelectedApp(null);
  };

  // Section functions
  const onClickAddSection = () => {
    setSelectedSectionId('');
    setShowSection(true);
  };

  const onClickRemoveSection = (id: string) => {
    setSelectedSectionId('');
    // @ts-ignore
    dispatchStore(removeSection(id));
  };

  const onClickEditSection = (id: string) => {
    setSelectedSectionId(id);
    setShowSection(true);
  };

  const closeModalSection = () => {
    setShowSection(false);
    setSelectedSectionId('');
  };

  return (
    <>
      {hasRole && (
        <div style={{ display: 'flex', margin: '10px 0 20px' }}>
          <Switch
            onClick={handleToggle}
            toggled={isEditMode}
            name="EDIT MODE"
            id="isEditMode"
          />
          {isEditMode && (
            <Button
              id="brand-button"
              buttonType="primary"
              onClick={() => onClickAddSection()}
              style={{ marginLeft: 'auto' }}
            >
              Add section
            </Button>
          )}
        </div>
      )}
      {sections.map(section => (
        <Fragment key={section?.id}>
          <div style={{ display: 'flex' }}>
            <H2>{section?.title}</H2>
            {isEditMode && hasRole && (
              <>
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  color="#006BFD"
                  size="2x"
                  style={{
                    marginLeft: '10px',
                    cursor: 'pointer',
                    alignSelf: 'flex-end',
                  }}
                  onClick={() => onClickAddButton(section?.id)}
                  title="Add new app"
                />
                <div style={{ marginLeft: 'auto' }}>
                  <FontAwesomeIcon
                    icon={faEdit}
                    color="rgb(3, 0, 208, 0.7)"
                    size="2x"
                    style={{
                      marginLeft: '0px',
                      cursor: 'pointer',
                    }}
                    onClick={() => onClickEditSection(section?.id)}
                    title="Edit the section"
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    color="rgb(217, 0, 0, 0.7)"
                    size="2x"
                    style={{
                      marginLeft: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => onClickRemoveSection(section?.id)}
                    title="Remove the section"
                  />
                </div>
              </>
            )}
          </div>
          {section?.items?.length === 0 ? (
            <div
              style={{
                width: '50%',
                marginTop: '25px',
                marginBottom: '55px',
              }}
            >
              <Alert id="default" severity="info">
                There is no app in this section.
              </Alert>
            </div>
          ) : (
            <AppSectionContainer>
              {section?.items?.map(item => (
                <div key={item.appName} style={{ display: 'flex' }}>
                  {isEditMode && hasRole && (
                    <div
                      style={{
                        marginRight: '-40px',
                        paddingBottom: '6px',
                        zIndex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        color="rgb(217, 0, 0, 0.7)"
                        size="1x"
                        style={{
                          margin: '0px',
                          cursor: 'pointer',
                        }}
                        onClick={e => onClickRemoveButton(e, section?.id, item)}
                        title="Remove the app"
                      />
                      <FontAwesomeIcon
                        icon={faEdit}
                        color="rgb(3, 0, 208, 0.7)"
                        size="1x"
                        style={{
                          marginLeft: '5px',
                          cursor: 'pointer',
                        }}
                        onClick={e => onClickEditButton(e, section?.id, item)}
                        title="Edit the app"
                      />
                    </div>
                  )}
                  <Tile
                    {...item}
                    onClick={() => handleClick(item.appUrl)}
                    key={item.appName}
                  />
                </div>
              ))}
            </AppSectionContainer>
          )}
        </Fragment>
      ))}
      <Modal
        width="50%"
        onClose={closeModal}
        open={show}
        title={selectedApp ? 'Edit the app' : 'Add new app'}
      >
        <CreateAppTile
          closeModal={closeModal}
          selectedSection={findSection}
          selectedApp={selectedApp}
        />
      </Modal>

      <Modal
        width="50%"
        onClose={closeModalSection}
        open={showSection}
        title="Add new section"
      >
        <CreateSection
          closeModal={closeModalSection}
          selectedSection={findSection}
          apiResponse={apiResponse}
        />
      </Modal>
    </>
  );
};
