/* eslint-disable no-param-reassign */
import { StateStatus } from './state.types';

export const loadingState = <T extends StateStatus>(state: T) => {
  state.status = 'loading';
  state.error = null;
};

export const resetStatus = <T extends StateStatus>(state: T) => {
  state.status = 'idle';
  state.error = null;
};
