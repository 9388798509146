import styled from 'styled-components';

import { device } from '@azu-ignite/ci-core-ui-pattern-lib';
import backgroundImage from '../../images/ci-background.jpg';

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.items.authBackground};
  height: 100%;
  @media ${device.tablet} {
    justify-content: flex-start;
    background: ${props => props.theme.items.authBackground};
  }
`;

export const LoginBox = styled.div`
  padding: ${props => props.theme.spaces.lg};
  text-align: center;
  width: 100%;
  background: ${props => props.theme.items.authBackground};
  @media ${device.tablet} {
    text-align: left;
    border-radius: 10px;
    height: 100%;
    margin: ${props => props.theme.spaces.sm};
  }
`;

export const LoginContent = styled.div`
  max-width: 648px;
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: ${props => props.theme.spaces.sm};
  }
`;

export const DisclaimerText = styled.div`
  padding-top: ${props => props.theme.spaces.xxl};
  font-size: 12px;
  text-align: left;
  @media ${device.tablet} {
    padding-top: ${props => props.theme.spaces.lg};
  }
`;

export const VersionText = styled.div`
  font-size: 10px;
  padding-top: ${props => props.theme.spaces.md};
  text-align: left;
`;

export const LoginAction = styled.div`
  padding-top: ${props => props.theme.spaces.lg};
  padding-bottom: ${props => props.theme.spaces.md};
  @media ${device.tablet} {
    max-width: 354px;
  }
`;

export const LoginBrand = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  padding-top: ${props => props.theme.spaces.xl};
  padding-bottom: ${props => props.theme.spaces.xl};
  @media ${device.tablet} {
    justify-content: flex-start;
  }
`;

export const LoginStraplineText = styled.div`
  padding: ${props => props.theme.spaces.lg} 0;
  text-align: left;
`;

export const LoginStrapline = styled.div`
  display: block;
`;

export const LoginSideBar = styled.div`
  height: 100%;
  display: none;
  @media ${device.tablet} {
    display: block;
    background: url(${backgroundImage}) no-repeat center center;
    background-size: cover;
    overflow: hidden;
    width: 25%;
  }
`;

export const LoginFormElement = styled.div`
  margin-bottom: ${props => props.theme.spaces.sm};
  @media ${device.tablet} {
    max-width: 354px;
  }
`;
export const LogoutContainer = styled.div`
  float: right;
`;
