import styled from 'styled-components';
import { device } from '@azu-ignite/ci-core-ui-pattern-lib';

export const WarningBrandLogoContainer = styled.div`
  margin-bottom: ${props => props.theme.spaces.md};
  img {
    width: 40px;
  }
`;

export const WarningImage = styled.img`
  max-width: 100%;
  text-align: center;
  @media ${device.tablet} {
    max-width: none;
  }
`;

export const PageContent = styled.div`
  max-width: 972px;
  margin: auto;
`;
