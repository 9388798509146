/* eslint-disable no-plusplus */
export const hasUserRole = (roles: string[]) => {
  const HOME_EDITOR = 'HOME_EDITOR';

  if (roles?.includes(HOME_EDITOR)) {
    return true;
  }

  return false;
};

export const generateId = (length: number) => {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charLengths = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charLengths));
  }

  return result;
};
