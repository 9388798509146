/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useReducer, useEffect, useMemo } from 'react';
import { Input } from '@azu-ignite/ci-core-ui-pattern-lib';
import Select from 'react-select';
import { useAppDispatch } from '../../state/hooks';
import { createTileApp } from '../../state/slices';
import {
  Container,
  CancelButton,
  UpdateButton,
  DropLabel,
} from './CreateAppTile.styles';
import { iconURL } from '../../App.constants';
import { Switch } from './Switch';

type State = {
  appName: string;
  shortDescription: string;
  appUrl: string;
  appIconUrl: string;
  comingSoon: boolean;
  appActive: boolean;
  locked: boolean;
  beta: boolean;
  ai: boolean;
  order: any;
};

type Action = {
  type: 'UPDATE_FIELD';
  name: string;
  value: any;
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return { ...state, [action.name]: action.value };
    default:
      return state;
  }
};

export const CreateAppTile = ({
  selectedSection = {},
  selectedApp,
  closeModal,
}: {
  selectedSection: any;
  selectedApp: any;
  closeModal: () => void;
}) => {
  const dispatchStore = useAppDispatch();
  const [state, dispatch] = useReducer(reducer, {
    appName: '',
    shortDescription: '',
    appUrl: 'https://dev.ci.astrazeneca.com/core/#/coming-soon',
    appIconUrl: iconURL,
    comingSoon: false,
    appActive: true,
    locked: false,
    beta: false,
    ai: false,
    order: '',
  });

  useEffect(() => {
    if (selectedApp) {
      dispatch({
        type: 'UPDATE_FIELD',
        name: 'appName',
        value: selectedApp?.appName,
      });
      dispatch({
        type: 'UPDATE_FIELD',
        name: 'shortDescription',
        value: selectedApp?.shortDescription,
      });
      dispatch({
        type: 'UPDATE_FIELD',
        name: 'appUrl',
        value: selectedApp?.appUrl,
      });
      dispatch({
        type: 'UPDATE_FIELD',
        name: 'appIconUrl',
        value: selectedApp?.appIconUrl,
      });
      dispatch({
        type: 'UPDATE_FIELD',
        name: 'comingSoon',
        value: selectedApp?.comingSoon,
      });
      dispatch({
        type: 'UPDATE_FIELD',
        name: 'appActive',
        value: selectedApp?.appActive,
      });
      dispatch({
        type: 'UPDATE_FIELD',
        name: 'locked',
        value: selectedApp?.locked,
      });
      dispatch({
        type: 'UPDATE_FIELD',
        name: 'beta',
        value: selectedApp?.beta,
      });
      dispatch({
        type: 'UPDATE_FIELD',
        name: 'ai',
        value: selectedApp?.ai,
      });
      dispatch({
        type: 'UPDATE_FIELD',
        name: 'order',
        value: selectedApp?.order,
      });
    }
  }, []);

  const getOptions = useMemo(() => {
    const appList = [...selectedSection.appList];

    const existingAppListOptions = appList?.map((el: any, i: number) => ({
      value: i + 1,
      label: i + 1,
    }));

    if (!selectedApp) {
      const newAppList = [
        ...existingAppListOptions,
        {
          value: appList.length + 1,
          label: appList.length + 1,
        },
      ];
      return newAppList;
    }
    return existingAppListOptions;
  }, [selectedSection, selectedApp]);

  const updateChanges = () => {
    const copyAppList = [...selectedSection.appList];
    const finalState = {
      ...state,
      appTypeId: null,
    };

    // Create new app-tile
    if (!selectedApp) {
      if (!state.order) {
        copyAppList.splice(copyAppList.length, 0, finalState);
      }
      if (state.order) {
        copyAppList.splice(state.order - 1, 0, finalState);
      }
      const orderedAppList = copyAppList.map((el, i) => ({
        ...el,
        order: i + 1,
      }));

      const finalSection = {
        ...selectedSection,
        appList: orderedAppList,
      };
      // @ts-ignore
      dispatchStore(createTileApp(finalSection));
    }

    // Edit existing app-tile
    if (selectedApp) {
      const filteredList = copyAppList.filter(
        el => el.appName !== selectedApp.appName,
      );

      filteredList.splice(state.order - 1, 0, finalState);
      const orderedEditAppList = filteredList.map((el, i) => ({
        ...el,
        order: i + 1,
      }));

      const finalEditSection = {
        ...selectedSection,
        appList: orderedEditAppList,
      };
      // @ts-ignore
      dispatchStore(createTileApp(finalEditSection));
    }

    closeModal();
  };

  const handleInputChange = (name: keyof State, value: string) => {
    dispatch({ type: 'UPDATE_FIELD', name, value });
  };

  const handleDropChange = (selected: any) => {
    dispatch({ type: 'UPDATE_FIELD', name: 'order', value: selected.value });
  };

  const handleToggle = (status: any, name: keyof State) => {
    dispatch({ type: 'UPDATE_FIELD', name, value: status });
  };

  return (
    <div>
      <Container>
        <div
          style={{
            display: 'grid',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Input
            id="appName"
            name="appName"
            value={state.appName}
            onChange={e => handleInputChange('appName', e.target.value)}
            type="text"
            label="APP NAME"
            placeholder=""
          />
          <Input
            id="shortDescription"
            name="shortDescription"
            value={state.shortDescription}
            onChange={e =>
              handleInputChange('shortDescription', e.target.value)
            }
            type="text"
            label="SHORT DESCRIPTION"
            placeholder=""
          />
          <DropLabel>ORDER</DropLabel>
          <div>
            <Select
              aria-label="phase"
              name="order"
              id="order"
              options={getOptions}
              value={getOptions.find((el: any) => el?.value === state?.order)}
              onChange={handleDropChange}
              placeholder=""
              isClearable={false}
              isSearchable={false}
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  border: '1px solid #d7d7d7',
                  minHeight: '50px',
                  height: '50px',
                  width: '100%',
                  marginBottom: '23px',
                  fontSize: '14px',
                }),
                valueContainer: baseStyles => ({
                  ...baseStyles,
                  height: '50px',
                  padding: '0 10px',
                }),
                input: baseStyles => ({
                  ...baseStyles,
                  margin: '0px',
                }),
                indicatorsContainer: baseStyles => ({
                  ...baseStyles,
                  height: '50px',
                }),
                indicatorSeparator: baseStyles => ({
                  ...baseStyles,
                  display: 'none',
                }),
                singleValue: baseStyles => ({
                  ...baseStyles,
                  paddingBottom: '17px',
                }),
              }}
            />
          </div>
          <Input
            id="appUrl"
            name="appUrl"
            value={state.appUrl}
            onChange={e => handleInputChange('appUrl', e.target.value)}
            type="text"
            label="APP URL"
            placeholder=""
          />
          <Input
            id="appIconUrl"
            name="appIconUrl"
            value={state.appIconUrl}
            onChange={e => handleInputChange('appIconUrl', e.target.value)}
            type="text"
            label="APP ICON URL"
            placeholder=""
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'space-around',
          }}
        >
          <Switch
            onClick={handleToggle}
            toggled={state.comingSoon}
            name="COMING SOON"
            id="comingSoon"
          />
          <Switch
            onClick={handleToggle}
            toggled={state.appActive}
            name="APP ACTIVE"
            id="appActive"
            disabled
          />
          <Switch
            onClick={handleToggle}
            toggled={state.locked}
            name="LOCKED"
            id="locked"
          />
          <Switch
            onClick={handleToggle}
            toggled={state.beta}
            name="BETA"
            id="beta"
          />
          <Switch onClick={handleToggle} toggled={state.ai} name="AI" id="ai" />
        </div>
      </Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginBottom: '30px',
        }}
      >
        <CancelButton
          onClick={() => {
            closeModal();
          }}
          id="cancel-btn"
        >
          Cancel
        </CancelButton>
        <UpdateButton onClick={() => updateChanges()} id="update-btn">
          Save
        </UpdateButton>
      </div>
    </div>
  );
};
