/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoginFormData } from '../../pages/Login/Login.types';
import { EmptyObject } from '../../App.types';
import { postAuthenticate } from '../../services/loginService';
import { getConfigObj } from '../parsers/loginParser';
import { loadingState } from '../state.helpers';
import { StateStatus } from '../state.types';
import { Login } from '../../models';

/* Define state structure */
type LoginState = {
  login: Login | EmptyObject;
} & StateStatus;

/* Set initial state */
const initialState: LoginState = {
  login: {},
  status: 'idle',
};

export const authenticate = createAsyncThunk(
  'login/authenticate',
  async (data: LoginFormData) => {
    const loginInfo = getConfigObj(data);
    const res = await postAuthenticate(loginInfo);
    return res.data;
  },
);

const LoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetLogin: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(authenticate.pending, loadingState)
      .addCase(authenticate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.login = action.payload;
      })
      .addCase(authenticate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetLogin } = LoginSlice.actions;
export default LoginSlice.reducer;
