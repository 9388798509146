import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const InfoLinkText = styled(Link)`
  display: flex;
  justify-content: end;
  font-size: 15px;
  color: #0e7d86;
  margin-bottom: 10px;
  text-decoration: none;
  &:hover {
    font-weight: bold;
  }
`;

export const Divider = styled.div`
  opacity: 20%;
  border-bottom: 2px solid #3f4444;
  margin-bottom: 10px;
`;
