import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { PAGES } from '../../state/constants';
import pageImage from '../../images/homePage.png';
import oncologyImage from '../../images/oncologyPage.png';
import biopharmaImage from '../../images/biopharmaPage.png';
import alexionImage from '../../images/alexionPage.png';
import datascienceImage from '../../images/dataSciencePage.png';

const backgroundImages = {
  [PAGES.HOME]: `url(${pageImage})`,
  [PAGES.ONCOLOGY]: `url(${oncologyImage})`,
  [PAGES.BIOPHARMA]: `url(${biopharmaImage})`,
  [PAGES.ALEXION]: `url(${alexionImage})`,
  [PAGES.DATA_SCIENCE]: `url(${datascienceImage})`,
};

export const PageContainer = styled.div`
  background: #fbe3ed;
`;

export const Layout = styled.div<{ isTabletView?: boolean }>`
  padding: 0px 60px 120px;

  ${props =>
    props.isTabletView &&
    `
      padding: 0px 30px 40px;
  `}
`;

export const BackHome = styled.div<{ isTabletView?: boolean }>`
  border-bottom: 1px solid #1e1f20;
  padding: 14px 0;
`;

export const BackHomeWrapper = styled.div<{ isTabletView?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  font-family: 'Helvetica Neue';
  font-color: #1e1f20;

  > span {
    margin-left: 5px;
  }

  &:hover {
    color: #6f7071;
  }
`;

export const Container = styled.div<{ isTabletView?: boolean }>`
  max-width: calc(100% - 620px);
  color: #1e1f20;
  padding-right: 20px;
  padding-top: 55px;

  ${props =>
    props.isTabletView &&
    `
      max-width: 600px;
      padding-right: 0px;
  `}
`;

export const Heading = styled.p<{ isTabletView?: boolean }>`
  line-height: 22px;
  font-size: 23px;
  font-family: 'Lexia';

  ${props =>
    props.isTabletView &&
    `
      font-size: 22px;
  `}
`;

export const Description = styled.p<{ isTabletView?: boolean }>`
  line-height: 26px;
  font-size: 16px;
  margin: 30px 0 60px;

  ${props =>
    props.isTabletView &&
    `
      font-size: 15px;
  `}
`;

export const Background = styled.div<{ bgKey: string; isTabletView?: boolean }>`
  width: 100%;
  min-height: 90vh;
  background-image: ${({ bgKey }) => backgroundImages[bgKey || PAGES.HOME]};
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;

  ${props =>
    props.isTabletView &&
    `
      background-size: cover;
      background-position: center;
      background-image: none;
  `}
`;

export const Tabs = styled.div<{ isTabletView?: boolean }>`
  background: #830051;
  height: 60px;
  position: sticky;
  top: 72px;
  color: #fff;
  zindex: 999;
  font-family: 'HelveticaNeue';
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 40px;

  ${props =>
    props.isTabletView &&
    ` 
      padding-left: 20px;
      font-size: 13px;
      height: 54px;
  `}
`;

export const Tab = styled.div<{ selected?: boolean; isTabletView?: boolean }>`
  padding: 0 20px;
  color: white;
  font-weight: ${props => (props.selected ? '700' : '300')};
  position: relative;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    color: ${props => (props.selected ? '#ffffff' : '#d9d9d9')};
  }

  ${props =>
    !props.isTabletView &&
    ` 
      &::after {
        content: ${props.selected ? '""' : 'none'};
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fbe3ed;
      }
  `}

  ${props =>
    props.isTabletView &&
    ` 
      padding: 0 10px;
      border-right: 1px solid rgba(255,255,255,0.5);
      &:last-child {
        border-right: none;
      }
  `}
`;

export const TwoColWrapper = styled.div<{ isTabletView?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  ${props =>
    props.isTabletView &&
    `
      grid-template-columns: 1fr;
      gap: 25px;
  `}
`;

export const ThreeColWrapper = styled.div<{ isTabletView?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  ${props =>
    props.isTabletView &&
    `
      grid-template-columns: 1fr;
      gap: 25px;
  `}
`;

export const Divider = styled.div`
  opacity: 10%;
  border-bottom: 1px solid #1e1f20;
  margin: 30px 0;
`;

export const InfoLinkText = styled(Link)<{ isTabletView?: boolean }>`
  color: #ffffff;
  text-decoration: none;
  margin-left: auto;
  padding-right: 40px;
  cursor: pointer;
  font-style: italic;
  &:hover {
    color: #d9d9d9;
  }

  ${props =>
    props.isTabletView &&
    `
      padding-right: 5px;
      font-size: 12px;
  `}
`;
