// eslint-disable-next-line import/no-extraneous-dependencies
import { Portal } from 'react-portal';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MutableRefObject, useEffect, useRef } from 'react';
import { useClickOutside } from '../../hooks/useClickOutside';
import {
  ModalOverlay,
  ModalContainer,
  ModalContent,
  ModalClose,
  ModalHeader,
  Title,
} from './Modal.styles';
import { ModalProps } from './Modal.types';

export const Modal = ({
  children,
  title,
  onClose,
  onOpen,
  open = false,
  width = '80%',
}: ModalProps) => {
  const modalRef = useRef() as MutableRefObject<HTMLDivElement>;

  useClickOutside(modalRef, () => onClose());
  useEffect(() => {
    if (open && onOpen) onOpen();
  }, [open, onOpen]);

  return (
    <Portal>
      {open ? (
        <ModalOverlay>
          <ModalContainer width={width} ref={modalRef}>
            <ModalHeader>
              <Title>{title}</Title>
              <ModalClose onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} />
              </ModalClose>
            </ModalHeader>
            <ModalContent>{children}</ModalContent>
          </ModalContainer>
        </ModalOverlay>
      ) : null}
    </Portal>
  );
};
