/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useIsOnTablet } from '@azu-ignite/ci-core-ui-pattern-lib';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Loader } from '@azu-ignite/ci-design-system';
import { UserCookieConsent } from '../../components';
import {
  Background,
  PageContainer,
  Tabs,
  Tab,
  ThreeColWrapper,
  TwoColWrapper,
  Divider,
  InfoLinkText,
  Layout,
  BackHome,
  BackHomeWrapper,
  Container,
  Heading,
  Description,
} from './Home.styles';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { appMetaDataSelector } from '../../state/selectors';
import { fetchAppMetaData } from '../../state/slices';
import {
  PAGES,
  homeText,
  oncologyText,
  biopharmaText,
  alexionText,
  dataScienceText,
} from '../../state/constants';
import oncologyIcon from '../../images/Oncology_MULBERRY.svg';
import biopharmaIcon from '../../images/Biopharma_Mulberry.svg';
import alexionIcon from '../../images/Alexion_Filled_Mulberry.svg';
import dataScienceIcon from '../../images/data_science2.png';
import arrowLeftIcon from '../../images/left-arrow.svg';
import Tile from './Tile';

const findSection = (secs: any[], secTitle: string) => {
  if (!secs || !secs?.length) {
    return [];
  }
  const targetSec = secs.find((sec: any) => sec?.title === secTitle);
  return targetSec?.items;
};

export const Home = () => {
  const isTabletView = useIsOnTablet();
  const dispatch = useAppDispatch();
  const [activePage, setActivePage] = useState(PAGES.HOME);

  const { status: appStatus, sections } = useAppSelector(appMetaDataSelector);
  const isLoading = appStatus === 'loading';
  const isFailed = appStatus === 'failed';

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchAppMetaData());
  }, []);

  const handleClick = (url: string) => {
    window.location.href = url;
  };

  const navigatePage = (page: string) => {
    setActivePage(page);
  };

  const content = useMemo(() => {
    const oncologyApps = findSection(sections, PAGES.ONCOLOGY);
    switch (activePage) {
      case PAGES.ONCOLOGY:
        return (
          <Layout isTabletView={isTabletView}>
            <BackHome>
              <BackHomeWrapper onClick={() => setActivePage(PAGES.HOME)}>
                <img alt="right arrow" src={arrowLeftIcon} />
                <span>Back to home</span>
              </BackHomeWrapper>
            </BackHome>
            <Container isTabletView={isTabletView}>
              <Heading isTabletView={isTabletView}>{PAGES.ONCOLOGY}</Heading>
              <Description isTabletView={isTabletView}>
                {oncologyText}
              </Description>
            </Container>
            <TwoColWrapper isTabletView={isTabletView}>
              {!isLoading && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '30px',
                    }}
                  >
                    <div
                      style={{
                        fontFamily: 'Lexia',
                        fontWeight: 400,
                        fontSize: '23px',
                        marginBottom: '-10px',
                      }}
                    >
                      R&D
                    </div>
                    <Tile
                      text={oncologyApps?.[2]?.appName}
                      url={oncologyApps?.[2]?.appUrl}
                      navigatePage={handleClick}
                      icon={oncologyApps?.[2]?.appIcon}
                    />
                    <Tile
                      text={oncologyApps?.[3]?.appName}
                      url={oncologyApps?.[3]?.appUrl}
                      navigatePage={handleClick}
                      icon={oncologyApps?.[3]?.appIcon}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '30px',
                    }}
                  >
                    <div
                      style={{
                        fontFamily: 'Lexia',
                        fontWeight: 400,
                        fontSize: '23px',
                        marginBottom: '-10px',
                      }}
                    >
                      OBU
                    </div>
                    <Tile
                      text={oncologyApps?.[0]?.appName}
                      url={oncologyApps?.[0]?.appUrl}
                      navigatePage={handleClick}
                      icon={oncologyApps?.[0]?.appIcon}
                    />
                    <Tile
                      text={oncologyApps?.[1]?.appName}
                      url={oncologyApps?.[1]?.appUrl}
                      navigatePage={handleClick}
                      icon={oncologyApps?.[1]?.appIcon}
                    />
                  </div>
                </>
              )}
            </TwoColWrapper>
          </Layout>
        );
      case PAGES.BIOPHARMA:
        return (
          <Layout isTabletView={isTabletView}>
            <BackHome>
              <BackHomeWrapper onClick={() => setActivePage(PAGES.HOME)}>
                <img alt="right arrow" src={arrowLeftIcon} />
                <span>Back to home</span>
              </BackHomeWrapper>
            </BackHome>
            <Container isTabletView={isTabletView}>
              <Heading isTabletView={isTabletView}>{PAGES.BIOPHARMA}</Heading>
              <Description isTabletView={isTabletView}>
                {biopharmaText}
              </Description>
            </Container>
            <TwoColWrapper isTabletView={isTabletView}>
              {!isLoading &&
                findSection(sections, PAGES.BIOPHARMA)?.map((el: any) => (
                  <Tile
                    key={el?.appName}
                    text={el?.appName}
                    url={el?.appUrl}
                    navigatePage={handleClick}
                    icon={el?.appIcon}
                  />
                ))}
            </TwoColWrapper>
          </Layout>
        );
      case PAGES.ALEXION:
        return (
          <Layout isTabletView={isTabletView}>
            <BackHome>
              <BackHomeWrapper onClick={() => setActivePage(PAGES.HOME)}>
                <img alt="right arrow" src={arrowLeftIcon} />
                <span>Back to home</span>
              </BackHomeWrapper>
            </BackHome>
            <Container isTabletView={isTabletView}>
              <Heading isTabletView={isTabletView}>{PAGES.ALEXION}</Heading>
              <Description isTabletView={isTabletView}>
                {alexionText}
              </Description>
            </Container>
            <TwoColWrapper isTabletView={isTabletView}>
              {!isLoading &&
                findSection(sections, PAGES.ALEXION)?.map((el: any) => (
                  <Tile
                    key={el?.appName}
                    text={el?.appName}
                    url={el?.appUrl}
                    navigatePage={handleClick}
                    icon={el?.appIcon}
                  />
                ))}
            </TwoColWrapper>
          </Layout>
        );
      case PAGES.DATA_SCIENCE:
        return (
          <Layout isTabletView={isTabletView}>
            <BackHome>
              <BackHomeWrapper onClick={() => setActivePage(PAGES.HOME)}>
                <img alt="right arrow" src={arrowLeftIcon} />
                <span>Back to home</span>
              </BackHomeWrapper>
            </BackHome>
            <Container isTabletView={isTabletView}>
              <Heading isTabletView={isTabletView}>
                {PAGES.DATA_SCIENCE}
              </Heading>
              <Description isTabletView={isTabletView}>
                {dataScienceText}
              </Description>
            </Container>
            <TwoColWrapper isTabletView={isTabletView}>
              {!isLoading &&
                findSection(sections, PAGES.DATA_SCIENCE)?.map((el: any) => (
                  <Tile
                    key={el?.appName}
                    text={el?.appName}
                    url={el?.appUrl}
                    navigatePage={handleClick}
                    icon={el?.appIcon}
                  />
                ))}
            </TwoColWrapper>
          </Layout>
        );
      default:
        return (
          <Layout isTabletView={isTabletView}>
            <Container isTabletView={isTabletView}>
              <Heading isTabletView={isTabletView}>
                Welcome To Connected Insights
              </Heading>
              <Description isTabletView={isTabletView}>{homeText}</Description>
            </Container>
            <ThreeColWrapper isTabletView={isTabletView}>
              <Tile
                text={PAGES.ONCOLOGY}
                navigatePage={navigatePage}
                icon={oncologyIcon}
              />
              <Tile
                text={PAGES.BIOPHARMA}
                navigatePage={navigatePage}
                icon={biopharmaIcon}
              />
              <Tile
                text={PAGES.ALEXION}
                navigatePage={navigatePage}
                icon={alexionIcon}
              />
            </ThreeColWrapper>
            <Divider />
            <ThreeColWrapper isTabletView={isTabletView}>
              <Tile
                text={PAGES.DATA_SCIENCE}
                navigatePage={navigatePage}
                icon={dataScienceIcon}
              />
            </ThreeColWrapper>
          </Layout>
        );
    }
  }, [activePage, isTabletView]);

  const shortText = useCallback(
    (tab: any) => {
      if (isTabletView) {
        if (tab === PAGES.BIOPHARMA) {
          return 'BioPharma';
        }
        if (tab === PAGES.ALEXION) {
          return 'Alexion';
        }
        return tab;
      }
      return tab;
    },
    [isTabletView],
  );

  if (isFailed) {
    return null;
  }
  return (
    <>
      <UserCookieConsent />
      <Tabs isTabletView={isTabletView}>
        {Object.keys(PAGES)?.map((el: any) => (
          <Tab
            key={el}
            onClick={() => setActivePage(PAGES[el])}
            selected={activePage === PAGES[el]}
            isTabletView={isTabletView}
          >
            {shortText(PAGES[el])}
          </Tab>
        ))}
        {!isTabletView && (
          <InfoLinkText
            isTabletView={isTabletView}
            id="ci-info-page"
            to="/connected-insights"
          >
            What is Connected Insights?
          </InfoLinkText>
        )}
      </Tabs>
      {isLoading && <Loader isActive={isLoading} />}
      {appStatus === 'succeeded' && (
        <PageContainer>
          <Background bgKey={activePage} isTabletView={isTabletView}>
            {content}
          </Background>
        </PageContainer>
      )}
    </>
  );
};
