/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';
import { useIsOnTablet } from '@azu-ignite/ci-core-ui-pattern-lib';
import arrowRightIcon from '../../images/righ-arrow.svg';

const TileContainer = styled.a`
  display: inline-block;
  color: inherit;
  text-decoration: none;

  &:visited {
    color: inherit;
  }
  &:hover {
    color: inherit;
  }
  &:active {
    color: inherit;
  }
`;

const Container = styled.div<{ isTabletView?: boolean }>`
  height: ${props => (props.isTabletView ? '74px' : '94px')};
  background: #ffffff;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background: #d9d9d9;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-left: auto;
  }
`;

const ArrrowBox = styled.div<{ isTabletView?: boolean }>`
  height: ${props => (props.isTabletView ? '74px' : '94px')};
  min-width: ${props => (props.isTabletView ? '32px' : '44px')};
  background: #830051;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const IconImg = styled.img<{ isTabletView?: boolean }>`
  width: 70px;
  height: 50px;
  padding-left: 20px;
  ${props =>
    props.isTabletView &&
    `
      width: 60px;
      height: 50px;
      padding-left: 10px;
  `}
`;

const Text = styled.p<{ isTabletView?: boolean }>`
  padding: 0 10px 0 20px;
  font-family: 'Lexia';
  font-weight: 600;
  font-size: 19.2px;
  line-height: 22px;

  ${props =>
    props.isTabletView &&
    `
      padding: 0 4px 0 12px;
      font-size: 17.2px;
      line-height: 20px;
  `}
`;

const Tile = ({
  text,
  navigatePage,
  icon,
  url = '',
}: {
  text: string;
  navigatePage: (text: string) => void;
  icon: any;
  url?: string;
}) => {
  const isTabletView = useIsOnTablet();
  return (
    <TileContainer
      href={url}
      onClick={e => {
        e.preventDefault();
        navigatePage(url || text);
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Container isTabletView={isTabletView}>
        <Wrapper>
          <IconImg isTabletView={isTabletView} alt={text} src={icon} />
          <Text isTabletView={isTabletView}>{text}</Text>
          <ArrrowBox isTabletView={isTabletView}>
            <img alt="right arrow" src={arrowRightIcon} />
          </ArrrowBox>
        </Wrapper>
      </Container>
    </TileContainer>
  );
};

export default Tile;
