/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { CustomLabel } from './CreateAppTile.styles';

type SwitchProps = {
  onClick: (status: any, id: any) => void;
  toggled: boolean;
  name: string;
  id: string;
  disabled?: boolean;
};

export const Switch = ({
  onClick,
  toggled,
  name,
  id,
  disabled = false,
}: SwitchProps) => {
  const [isToggled, toggle] = useState<boolean>(false);

  useEffect(() => {
    toggle(toggled);
  }, [toggled]);

  const callback = () => {
    toggle(!isToggled);
    onClick(!isToggled, id);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <span
        style={{
          color: '#43476b',
          fontSize: '13px',
          fontFamily: 'HelveticaNeue-Bold',
          display: 'inline-block',
          marginRight: '10px',
        }}
      >
        {name}:
      </span>
      <CustomLabel htmlFor={id}>
        <input
          id={id}
          type="checkbox"
          checked={isToggled}
          onChange={callback}
          disabled={disabled}
        />
        <span />
      </CustomLabel>
    </div>
  );
};
