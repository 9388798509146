import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  display: block;
  overflow: auto;
  width: 100%;
  height: 100%;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const ModalContainer = styled.div<{ width?: string }>`
  height: 100%;
  max-height: 100%;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${props => props.theme.items.authBackground};
  display: flex;
  flex-direction: column;
  border-radius: 0;
  padding: 0px 40px;

  @media (min-width: 910px) {
    width: ${props => props.width};
    max-height: 90%;
    height: auto;
    border-radius: 8px;
  }
`;

export const ModalHeader = styled.header`
  padding: 40px 0 26px 0;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(63, 68, 68, 0.1);
`;

export const Title = styled.p`
  color: #b62264;
  font-family: 'Lexia-Regular';
  font-size: 25px;
`;

export const ModalClose = styled.span`
  display: inline-block;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover {
    opacity: 0.85;
    cursor: pointer;
  }
`;

export const ModalContent = styled.div`
  height: 100%;
  overflow-y: auto;
`;
