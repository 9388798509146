/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';
import { useIsOnTablet } from '@azu-ignite/ci-core-ui-pattern-lib';
import EmailIcon from '../../images/email.svg';

const Container = styled.div<{ isTabletView?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfilePicture = styled.img<{ isTabletView?: boolean }>`
  width: 220px;
  height: 220px;
  border-radius: 50%;
  margin-bottom: 15px;
  background: lightgray;
`;

const Name = styled.p<{ isTabletView?: boolean }>`
  font-family: 'Lexia';
  font-weight: 600;
  font-size: 19.2px;
  line-height: 22px;
  margin-bottom: 8px;
`;

const Role = styled.p<{ isTabletView?: boolean }>`
  font-family: 'Helvetica Neue';
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 8px;
`;

const EmailWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Email = styled.a<{ isTabletView?: boolean }>`
  font-family: 'Helvetica Neue';
  font-weight: 400;
  font-size: 13.33px;
  margin-left: 4px;
  text-decoration: none;
  color: inherit;

  &:hover {
    color: #000;
  }
`;

const Person = ({
  name,
  photo,
  role,
  email,
}: {
  name?: string;
  photo?: any;
  role?: string;
  email?: string;
}) => {
  const isTabletView = useIsOnTablet();
  return (
    <Container isTabletView={isTabletView}>
      <ProfilePicture src={photo} alt="" />
      <Name isTabletView={isTabletView}>{name}</Name>
      <Role isTabletView={isTabletView}>{role}</Role>
      <EmailWrapper>
        <img alt="email-icon" src={EmailIcon} />
        <Email href={`mailto:${email}`}>{email}</Email>
        {/* <Email>{email}</Email> */}
      </EmailWrapper>
    </Container>
  );
};

export default Person;
