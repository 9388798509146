import FrankPic from '../../images/FrankRobinson.png';
import HarveyPic from '../../images/HarveyTerry.png';
import RanjithPic from '../../images/RanjithMurugesan.png';
import GaneshPic from '../../images/Ganesh.jpg';
import JermainePic from '../../images/Jermaine_Grant.png';
import JasenPic from '../../images/Jasen_Chooramun2.jpg';
import HariPic from '../../images/Harikrishan.jpg';
import NecibePic from '../../images/Necibe.jpg';

export const teamData = [
  {
    name: 'Jasen Chooramun',
    role: 'Head of Connected Insights',
    email: 'jasen.chooramun@astrazeneca.com',
    photo: JasenPic,
  },
  {
    name: 'Frank Robinson',
    role: 'Senior IT Programme Manager',
    email: 'frank.robinson@astrazeneca.com',
    photo: FrankPic,
  },
  {
    name: 'Harvey Terry',
    role: 'Business Analyst, Commercial IT',
    email: 'harvey.terry@astrazeneca.com',
    photo: HarveyPic,
  },
  {
    name: 'Necibe Ahat',
    role: 'Senior Information Engineer',
    email: 'necibe.ahat@astrazeneca.com',
    photo: NecibePic,
  },
  {
    name: 'Ganesh Jalakam',
    role: 'Data Engineer',
    email: 'ganesh.jalakam@astrazeneca.com',
    photo: GaneshPic,
  },
  {
    name: 'Harikrishnan D',
    role: 'AI Engineer',
    email: 'harikrishnan.d@astrazeneca.com',
    photo: HariPic,
  },
  {
    name: 'Jermaine Grant',
    role: 'Senior Software Cloud Engineer​',
    email: 'jermaine.grant@astrazeneca.com',
    photo: JermainePic,
  },
  {
    name: 'Ranjith Murugesan',
    role: 'Data Engineer',
    email: 'ranjith.m@astrazeneca.com',
    photo: RanjithPic,
  },
];
